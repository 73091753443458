import React, { useState, useEffect } from "react"
import { Upload, Modal, message } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import API from "../../api"
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export const PictureCard = props => {
  const { imageUpdate, imageDelete } = API
  const [state, setState] = useState({
    previewVisible: false,
    previewImage: "",
    fileList: props.data ? [...props.data] : [],
  })
  useEffect(() => {
    setState({
      previewVisible: false,
      previewImage: "",
      fileList: props.data ? [...props.data] : [],
    })
  }, [props.data])

  const handleCancel = () => setState({ ...state, previewVisible: false })

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setState({
      ...state,
      previewImage: file.url || file.preview,
      previewVisible: true,
    })
  }
  const handleRemove = async fileList => {
    const { doc_id, url } = fileList
    if (url || doc_id) {
      const result = await imageDelete(doc_id, { url: url })
      if (result.data.success) {
        return true
      } else {
        message.error({ content: result.data.message, duration: 3 })
        return false
      }
    } else {
      return true
    }
    // props.removeImage(fileList?.doc_id)
  }
  const handleChange = info => {
    const { fileList } = info
    setState({ ...state, fileList })
    const arr = []
    fileList.map(item => {
      if (item.response) {
        arr.push(item.response.data.filepath)
        if (
          props.doc_name === "Post Wash Images" ||
          props.doc_name === "Pre Wash Images" ||
          props.doc_name === "Safety Sheets (SDS)"
        ) {
        } else {
          props.response(arr)
        }
      }
    })
    if (props.ticketUpdate) {
      uploadImg(info)
    }
  }
  const uploadImg = info => {
    if (info.file.status === "uploading") {
      // setBtnDisable(true)
    }
    if (info.file.status === "done") {
      const local = JSON.parse(localStorage.getItem("data"))
      let doc_type = ""
      local.TabParams.map(item => {
        if (item.param_description === props.doc_name) {
          doc_type = item.param_value
        }
      })

      const fileData = {
        doc_path: info.file.response.data.filepath,
        coordinates: [0.0, 0.0],
        asset_txns_id: props.asset_txns_id,
        doc_type: doc_type,
        doc_name: props.doc_name,
      }

      imageUpdate(fileData).then(res => {
        const _data = res.data.data
        const imageArr = [...fileList]
        if (_data) {
          imageArr.push({
            doc_id: _data.doc_id,
            uid: _data.doc_id,
            name: _data.doc_name,
            status: "done",
            url: _data.doc_path,
            thumbUrl: _data.doc_path,
          })
          props.responseTicket(_data.doc_id, _data.doc_path, _data.doc_name)
        }
        let newArry = imageArr.filter(item => {
          return item.doc_id
        })
        setState({ fileList: newArry })
        // setFileList(imageArr);
      })
      // setBtnDisable(false)
      // message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      // setBtnDisable(false);
      // message.error(`${info.file.name} file upload failed.`);
    } else if (info.file.status === "removed") {
      const imageArr = [...fileList]
      let url = {}
      let uid = ""
      let document = ""
      imageArr.map((item, i) => {
        if (!info.file.response) {
          if (item.url === info.file.url) {
            uid = item.doc_id
            url = { url: item.url }
            document = item.name
            imageArr.splice(i, 1)
          }
        } else if (info.file.response.data.filepath === item.url) {
          uid = item.doc_id
          url = { url: item.url }
          document = item.name
          imageArr.splice(i, 1)
        }
      })
      // setFileList(imageArr);
      props.canceledImages(uid, document)
      setState({ fileList: fileList.filter(item => item.doc_id !== uid) })
    }
  }
  const { previewVisible, previewImage, fileList } = state
  // console.log(fileList,'this is previe')
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  )
  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : ""
  const { GATSBY_BACKEND_URL } = process.env
  return (
    <div className="clearfix picture-upload-custom" style={props.style}>
      <Upload
        action={`https://ems.washtrax.com/image/upload`}
        headers={{
          Authorization: `Bearer ${token}`,
        }}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={props.hide ? handleChange : ""}
        onRemove={props.hide ? handleRemove : ""}
      >
        {fileList.length >= 8 ? null : props.hide ? uploadButton : null}
      </Upload>
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={handleCancel}
        style={{ marginTop: props.resp === "resp" ? "100px" : "" }}
      >
        <img
          alt="example"
          style={{
            width: "100%",
            marginTop: props.resp === "resp" ? "20px" : "",
          }}
          src={previewImage}
        />
      </Modal>
    </div>
  )
}

export default PictureCard
